module.exports = {
  global_key: {
    tokenName: 'access_token',
  },
  colors: {
    primaryColor: '#6f2b8f',
    secondaryColor: '#d42e12',
    backgroundColor: 'whitesmoke',
  },
  // apiKey: 'sJg1CBq2keb19nJOA0CENHkUt1wuHXyJro7Eudix9GwVPsT0', // ! myeduclass apiKey
  apiKey: 'cP2Ce8jbRcPy4DIJRVogC0oukivocdRbSvJ0qqmcU0RCk1gk',
};
