import uesLms from '../services';
import authService from './auth';

export default {
  async getStudentDetails() {
    try {
      await authService.refreshToken();
      const res = await uesLms().post('/user');
      return {
        success: true,
        data: res.data.data,
      };
    } catch (error) {
      return {
        success: false,
        data: error.message,
      };
    }
  },
  async getTimeStamp() {
    try {
      await authService.refreshToken();
      const res = await uesLms().get('/settings/datetime');
      return {
        success: true,
        data: res.data,
      };
    } catch (error) {
      return {
        success: false,
        data: error.message,
      };
    }
  },
  async setProfilePicture(data) {
    try {
      await authService.refreshToken();
      const res = await uesLms().post('/user/avatarUpdate', data);
      if (res.data.success === true) {
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  },
  async getTeacherStudents(organization) {
    const dataArr = [];
    await authService.refreshToken();
    const grades = organization.filter(x => x.type === 'grade');
    for (const gradeItem of grades) {  //eslint-disable-line
      const classObj = {};
      classObj.text = gradeItem.name;
      classObj.value = gradeItem.id;
      classObj.icon = 'fa fa-sitemap';
      classObj.type = 'class';
      classObj.children = [];
      classObj.selected = false;
      classObj.opened = false;
      const students = await uesLms().get(`organization/${gradeItem.id}/students`); //eslint-disable-line
      for (const studentItem of students.data) {  //eslint-disable-line
        const stuObj = {};
        stuObj.text = studentItem.student_name;
        stuObj.icon = 'fa fa-user';
        stuObj.value = studentItem.user_id;
        stuObj.type = 'student';
        stuObj.selected = false;
        stuObj.parentValue = gradeItem.id;
        classObj.children.push(stuObj);
      }
      dataArr.push(classObj);
    }
    return dataArr;
  },
  async createUser(data) {
    try {
      const res = await uesLms().post('/authenticate/createuser', data);
      return {
        success: true,
        data: res.data,
      };
    } catch (error) {
      return {
        success: false,
        data: error.message,
      };
    }
  },
  async createCompassStudent(data) {
    try {
      const res = await uesLms().post('/user/compass/student', data);
      console.log('data',data);
      return {
        success: true,
        data: res.data,
      };
    } catch (error) {
      return {
        success: false,
        data: error.message,
      };
    }
  },
};
