<template>
  <div class="row" v-if="!ready" :class="$style.divGenelRow">
      <InnerLoading></InnerLoading>
  </div>
  <div class="row" v-else :class="$style.divGenelRow">
      <!-- <div class="col-md-6 mt-1" v-if="roleType === 'student'">
        <a :href="`https://www.kidzwonder.com${querystingtext}`" target="_blank">
          <div :class="$style.divPlatform">
            <div style="background-size: 30% auto;" :class="[$style.divPlatformBtn, $style.divPlatformBtnKidzwonder]"></div>
            kapalı <div :class="$style.txtPlatform">AGK KIDZ</div> 
          </div>
        </a>
      </div>
      <div class="col-md-6" v-else>
        <div class="mt-1">
          <a :href="`https://www.uesportals.com/#/login/signin${querystingKidzReport}`" target="_blank">
            <div :class="$style.divPlatform">
              <div :class="[$style.divPlatformBtn, $style.divPlatformBtnKidzwonder]"></div>
            </div>
          </a>
        </div>
      </div> -->
      <!-- <div class="col-md-6 mt-1">
        <a>
            <div :class="$style.divPlatform" @click="checkHll()">
            <div :class="[$style.divPlatformBtn, $style.divPlatformBtnHighlights]"></div>
          </div>
        </a>
      </div> -->

        <div v-if="roleType === 'student'" class="col mt-1"><!-- Klasik Öğrenci -->
        <br/>
        
        <!-- 5,6,7. sınıflar Hexagonia --->
        <!-- <div class="col-md-6 mt-3" v-if="Number(grade) >= 5 && Number(grade) <= 7">  -->
          <!-- <span>Hexagonia</span> -->
          <!-- <a :href="`https://www.hexagonia.com.tr${hexaquerystingtext}`" target="_blank">
            <div :class="$style.divPlatform">
              <div :class="[$style.divPlatformBtn, $style.divPlatformBtnHexagonia]"></div>
            </div>
          </a>
        </div> -->

        <div class="col-md-6 mt-3" v-if="Number(grade) >= 5 && Number(grade) <= 7">  <!-- 1,2,3,4. sınıflar Kidzwonder --->
          <!-- <span>Kidzwonder</span> -->
            <a>
              <div :class="$style.divPlatform" @click="checkHll()">
              <div :class="[$style.divPlatformBtn, $style.divPlatformBtnHighlights]"></div>
              </div>
            </a>
        </div>

        <div class="col-md-6 mt-3" v-if="Number(grade) >= 1 && Number(grade) <= 4">  <!-- 1,2,3,4. sınıflar Kidzwonder --->
          <!-- <span>Kidzwonder</span> -->
            <a :href="`https://www.kidzwonder.com${kwquerystingtext}`" target="_blank">
              <div :class="$style.divPlatform">
                <div :class="[$style.divPlatformBtn, $style.divPlatformBtnKidzwonder]"></div>
              </div>
            </a>
        </div>

      </div>
      <div v-else-if="roleType === 'teacher'" class="col mt-2"> <!-- Klasik Öğretmen Bölümü -->
        <div class="col-md-6">
          <!-- <span>Hexagonia</span> -->
          <a>
            <div :class="$style.divPlatform" @click="checkHll()">
              <div :class="[$style.divPlatformBtn, $style.divPlatformBtnHighlights]"></div>
            </div>
          </a>
        </div>
        <div class="col-md-6">
          <!-- <span>Kidzwonder</span> -->
            <a :href="`https://www.uesportals.com/#/login/signin${kwquerystingtext}`" target="_blank">
              <div :class="$style.divPlatform">
                <div :class="[$style.divPlatformBtn, $style.divPlatformBtnKidzwonder]"></div>
              </div>
            </a>
        </div>

      </div>
    </div>
</template>

<script>
import swal from 'sweetalert';
// import jwt from 'jsonwebtoken';
import { mapState, mapActions } from 'vuex';
import services from '@/services/user';
// import axios from 'axios';
import InnerLoading from '@/components/InnerLoading';

export default {
  name: 'platforms',
  data() {
    return {
      ready: true,
      grade: '',
      level: '',
      roleType: '',
      querystingtext: '',
      kwquerystingtext: '',
      hexaquerystingtext: '',
      zoom: '',
      checkTime: true,
      logCompassSchool: '',
      logCompassUser: '',
      logCompassPass: '',
      ues_digital_code: '',
      ues_digital_code_check: '',
      compass_ready: false,
      querystingKidzReport: '',
    };
  },
  computed: {
    ...mapState(['userDetails', 'username', 'password']),
    levelName() {
      return this.$store.state.userDetails.organization.find(x => x.type === 'level').name;
    },
  },
  mounted(){
  },
  beforeMount() {
    this.setData();
    if(this.userDetails.uesdigital_code != null){
      this.ues_digital_code = this.userDetails.uesdigital_code;
      this.logCompassUser = atob(this.userDetails.compass_username);
      this.logCompassPass = atob(this.userDetails.compass_password);
      this.logCompassSchool = this.userDetails.compass_school;
    }
  },
  watch: {
    userDetails() {
      this.setData();
    },
  },
  components:{
    InnerLoading
  },
  methods: {
    ...mapActions(["getUserDetails"]),
    async timeStamp() {
      const res = await services.getTimeStamp();
      if (this.title === 'demo') return true;
      if (res.data.is_weekend) return false;
      // eslint-disable-next-line
      return (res.data.data.split(' ')[1] > '08:00:00') && (res.data.data.split(' ')[1] < '17:00:00') ? true : false;
    },
    async setData() {
      if (this.userDetails) {
        this.level = this.userDetails.organization.find(x => x.type === 'level').name;
        this.grade = this.userDetails.organization.find(x => x.type === 'grade') ? this.userDetails.organization.find(x => x.type === 'grade').name.split('-')[0] : 0;
        this.main = this.userDetails.organization.find(x => x.type === 'main').id;
        this.zoom = this.userDetails.organization.filter(x => x.type === 'grade');
        this.roleType = this.userDetails.role_type;
        this.title = this.userDetails.title;
        this.querystingtext = `?username=${btoa(this.username)}&password=${btoa(this.password)}`;

        this.querystingKidzReport = `?username=${btoa(this.username)}&password=${btoa(this.password)}&platformId=${btoa('23')}`;
        if (this.userDetails.lang === 'en') {
          this.hexaquerystingtext = `?username=${btoa('steve.jobs')}&password=${btoa('090807')}`;
        } else {
          this.hexaquerystingtext = this.querystingtext;
        }
        this.checkTime = await this.timeStamp();
      }
      // this.kwquerystingtext = `?username=${btoa('uesnlkw_teacher')}&password=${btoa('123456')}`;
      this.kwquerystingtext = this.querystingtext;
    },
    goTeacherLink(id) {
      this.$router.push({
        name: 'teachersResources',
        params: {
          id,
        },
      });
    },
    async checkHll() {
      try {
        this.ready = false;
        // const obj = [
        //   {
        //     schoolName: 'İSABET ÇEKMEKÖY',
        //     schoolId: 51036,
        //     companyKey: '6XN6uiQejnMWSzJ3gRCdKw=='
        //   },
        //   {
        //     schoolName: 'İSABET DUDULLU',
        //     schoolId: 51038,
        //     companyKey: 'gUhka8kgeqx1441Olwtbgg=='
        //   },
        //   {
        //     schoolName: 'İSABET BEYLİKDÜZÜ',
        //     schoolId: 51040,
        //     companyKey: 'lYo/kGJ2JWJoXXghYLoKaQ=='
        //   },
        //   {
        //     schoolName: 'İSABET KOCAELİ/ÇAYIROVA',
        //     schoolId: 51042,
        //     companyKey: 'mWlCR8alKkr5qztDBrpUOg=='
        //   },
        //   {
        //     schoolName: 'İSABET ADANA',
        //     schoolId: 51044,
        //     companyKey: 'U+bQQnXvq5TkvhkaqSTf0Q=='
        //   },
        //   {
        //     schoolName: 'İSABET AKSARAY',
        //     schoolId: 51046,
        //     companyKey: 'E+TAspT7g2qYAj2bsnOtaA=='
        //   },
        //   {
        //     schoolName: 'İSABET ANTALYA',
        //     schoolId: 50674,
        //     companyKey: 'Nu74EUK9YEVfPXRQbtYEmg=='
        //   },
        //   {
        //     schoolName: 'İSABET ALANYA',
        //     schoolId: 50691,
        //     companyKey: 'CdrdS7Zs6jpvKhRE0DtakA=='
        //   },
        //   {
        //     schoolName: 'İSABET AYDIN',
        //     schoolId: 50708,
        //     companyKey: 'ynJuGyJWuPYjc20Rpnzcmg=='
        //   },
        //   {
        //     schoolName: 'İSABET BALIKESİR',
        //     schoolId: 50710,
        //     companyKey: 'MahFHKeeFyB4Yk+l8yQgLg=='
        //   },
        //   {
        //     schoolName: 'İSABET BAYRAKLI',
        //     schoolId: 50712,
        //     companyKey: 'o2repso0QyIoaHdT9VZqoA=='
        //   },
        //   {
        //     schoolName: 'İSABET BURSA',
        //     schoolId: 50729,
        //     companyKey: 'OYA3IcxlSRTDGX+0+vBskA=='
        //   },
        //   {
        //     schoolName: 'İSABET GAZİANTEP MAHMUDİYE',
        //     schoolId: 50550,
        //     companyKey: '03JGKAGEzM2e3waS2i0IEw=='
        //   },
        //   {
        //     schoolName: 'İSABET İZMİR',
        //     schoolId: 50553,
        //     companyKey: 'ZNx/eFbjt54vECoW0Os6aQ=='
        //   },
        //   {
        //     schoolName: 'İSABET İZMİR ÖDEMİŞ',
        //     schoolId: 50555,
        //     companyKey: 'Gx/sj/wlYF01Accjf0bTLg=='
        //   },
        //   {
        //     schoolName: 'İSABET KARAMAN',
        //     schoolId: 50572,
        //     companyKey: 'OceqKFGpF37YSioBhXPIrg=='
        //   },
        //   {
        //     schoolName: 'İSABET KAYSERİ',
        //     schoolId: 50574,
        //     companyKey: 'xhPPkfa6EL/siTlgKDK2Zw=='
        //   },
        //   {
        //     schoolName: 'İSABET KONYA',
        //     schoolId: 50549,
        //     companyKey: 'qvkoJnhuzLuD/NQPYTXLLg=='
        //   },
        //   {
        //     schoolName: 'İSABET KONYA BEYŞEHİR',
        //     schoolId: 50575,
        //     companyKey: 'oIvg0ChtuNrcmXgU4dTc0A=='
        //   },
        //   {
        //     schoolName: 'İSABET KONYA EREĞLİ',
        //     schoolId: 50653,
        //     companyKey: 'S7cFYRkIWxaqGt4D6bVffw=='
        //   },
        //   {
        //     schoolName: 'İSABET KÜTAHYA',
        //     schoolId: 50655,
        //     companyKey: 'zS5QEQSo+Ab/3K2Kb+OE+Q=='
        //   },
        //   {
        //     schoolName: 'İSABET KÜTAHYA KADİR ÖZKANAT',
        //     schoolId: 50657,
        //     companyKey: '7qS6CT5qONmCJiO7W+JDzg=='
        //   },
        //   {
        //     schoolName: 'İSABET KÜTAHYA GEDİZ',
        //     schoolId: 50823,
        //     companyKey: '3g/utVK7F4YtsGZ/8VbAHg=='
        //   },
        //   {
        //     schoolName: 'İSABET KÜTAHYA TAVŞANLI',
        //     schoolId: 50840,
        //     companyKey: 'T01DP5HEbAkRHxUBfKGNuA=='
        //   },
        //   {
        //     schoolName: 'İSABET KÜTAHYA SİMAV',
        //     schoolId: 50889,
        //     companyKey: 'b8ZFrjifr88BXi7Igq4V+w=='
        //   },
        //   {
        //     schoolName: 'İSABET MANİSA SOMA',
        //     schoolId: 50841,
        //     companyKey: 'C7V4oXKct7AcF7QzJE1svw=='
        //   },
        //   {
        //     schoolName: 'İSABET SAMSUN',
        //     schoolId: 50806,
        //     companyKey: 'j6E0ygWYXrjEaZJxUM7KCA=='
        //   },
        //   {
        //     schoolName: 'İSABET UES KAMPÜSÜ',
        //     schoolId: 50476,
        //     companyKey: 'j6E0ygWYXrjEaZJxUM7KCA=='
        //   }
        // ];
        const userDetails = await services.getStudentDetails();
        const userId = userDetails.data.user_id;
        const firstName = userDetails.data.first_name;
        const lastName = userDetails.data.last_name;
        const userType = userDetails.data.role_type;
        const key = userDetails.data.organization.find(y => y.type === 'campus').hll_company_code;
        // const key = obj.find(x => x.schoolId === campusId).companyKey;
        // const key = 'C7V4oXKct7AcF7QzJE1svw==';
        // console.log('userDetails', userDetails);
        // console.log(userId, userId, firstName, lastName, userType, key);
        // window.go_hll(userId, userId, firstName, lastName, className, userType, key);
        if(userDetails.data.role_type === "student"){
          const className = encodeURIComponent(userDetails.data.organization.find(x => x.type === 'grade').name);
          window.go_hll(userId, userId, firstName, lastName, className, userType, key);
        }else if(userDetails.data.role_type === "teacher"){
          const classes = [];
          userDetails.data.organization.filter(x => x.type === 'grade').forEach((item) => {
            classes.push(encodeURIComponent(item.name))
          });
          window.go_hll(userId, userId, firstName, lastName, classes, userType, key);
        }
        setTimeout(() => {
          this.ready = true;
        }, 1000);
      } catch (error) {
        console.log('err', error);
        swal('Giriş Yapılamadı!', 'Bir Hata Oluştu', 'error');
        this.ready = true;
      }
    },
    query(){
      this.querystingKidzReport = `?username=${btoa(this.username)}&password=${btoa(this.password)}&platformId=${btoa('23')}`;
    }
  },
};
</script>

<style src='./style.less' lang='less' module/>

<style scoped>
  a {
    text-decoration: none;
    color: black;
  }
</style>
